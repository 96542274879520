<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 25 24"
    fill="none"
  >
    <g
      id="insights sharp"
      clip-path="url(#clip0_1447_6175)"
    >
      <g id="Vector">
        <path
          d="M21.3232 8C19.8732 8 19.0632 9.44 19.3932 10.51L15.8432 14.07C15.5432 13.98 15.1032 13.98 14.8032 14.07L12.2532 11.52C12.5932 10.45 11.7832 9 10.3232 9C8.87324 9 8.05324 10.44 8.39324 11.52L3.83324 16.07C2.76324 15.74 1.32324 16.55 1.32324 18C1.32324 19.1 2.22324 20 3.32324 20C4.77324 20 5.58324 18.56 5.25324 17.49L9.80324 12.93C10.1032 13.02 10.5432 13.02 10.8432 12.93L13.3932 15.48C13.0532 16.55 13.8632 18 15.3232 18C16.7732 18 17.5932 16.56 17.2532 15.48L20.8132 11.93C21.8832 12.26 23.3232 11.45 23.3232 10C23.3232 8.9 22.4232 8 21.3232 8Z"
          :fill="color"
        />
        <path
          d="M15.3232 9L16.2632 6.93L18.3232 6L16.2632 5.07L15.3232 3L14.4032 5.07L12.3232 6L14.4032 6.93L15.3232 9Z"
          :fill="color"
        />
        <path
          d="M3.82324 11L4.32324 9L6.32324 8.5L4.32324 8L3.82324 6L3.32324 8L1.32324 8.5L3.32324 9L3.82324 11Z"
          :fill="color"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1447_6175">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.323242)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'ic-insights-sharp',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    color: {
      type: String,
    },
  },
}
</script>

<style scoped></style>
